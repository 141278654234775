import type { Environment } from '@change-corgi/core/environment';

export const typesenseConfig: Record<Environment, { host: string; apiKey: string }> = {
	staging: {
		host: 'www.staging-change.org',
		apiKey:
			'cmFmeEFvL3BHdVUxSlhhRmYrd0FvWkVXTCtOL1ZmNGZmczBSMW9NcEpvTT02ZFozeyJmaWx0ZXJfYnkiOiJkaXNjb3ZlcmFibGU6dHJ1ZSJ9',
	},
	production: {
		host: 'www.change.org',
		apiKey:
			'MXhHNnR3aElSRXNqMVVReWJ4azBzbjBudkdGa251cGxEZ1RUTDRrL2czdz0xa3VJeyJmaWx0ZXJfYnkiOiJkaXNjb3ZlcmFibGU6dHJ1ZSJ9',
	},
	demo: {
		host: 'www.staging-change.org',
		apiKey:
			'cmFmeEFvL3BHdVUxSlhhRmYrd0FvWkVXTCtOL1ZmNGZmczBSMW9NcEpvTT02ZFozeyJmaWx0ZXJfYnkiOiJkaXNjb3ZlcmFibGU6dHJ1ZSJ9',
	},
	development: {
		host: 'www.staging-change.org',
		apiKey:
			'cmFmeEFvL3BHdVUxSlhhRmYrd0FvWkVXTCtOL1ZmNGZmczBSMW9NcEpvTT02ZFozeyJmaWx0ZXJfYnkiOiJkaXNjb3ZlcmFibGU6dHJ1ZSJ9',
	},
};
